<template>
  <!--begin::Contact Listing-->
  <div class="contact-template">
    <PageHeaderCount
      moduleType="contact"
      :dataLoading="dataLoading"
      :statusList="statusList"
      allkey="all_customers"
      countkey="customerperson_status_count"
    ></PageHeaderCount>
    <ListingTemplate
      :customClass="'contact-listing'"
      v-if="getPermission('contact:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-col cols="4" class="pt-0">
                <h1 class="form-title d-flex margin-auto selected-rows-text">
                  {{ totalSelected }} Selected
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('contact:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(more, index) in supplierMoreAction">
                        <v-list-item
                          link
                          v-on:click="updateMoreAction(more.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12" class="pt-0 col-lg-6">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_customers
                          }}</template>
                          <template v-else>{{
                            item.customerperson_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-col>
              <v-col
                cols="12"
                class="pt-0 justify-flex-end d-flex margin-auto col-lg-6 all-project-btn-right"
              >
                <template v-if="getPermission('contact:create')">
                  <v-btn
                    :disabled="dataLoading"
                    depressed
                    class="mx-2 custom-bold-button custom-btn-color white--text"
                    :to="getDefaultRoute('contact.create')"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                  <v-btn
                    v-if="false"
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="importDialog = true"
                    color="cyan white--text"
                    depressed
                  >
                    <v-icon dark left>mdi-database-import</v-icon> Import
                  </v-btn>
                </template>
                <!-- <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                  depressed
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn> -->
                <!--   <v-btn
                  v-if="!isEngineerChannel()"
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button btn-tab-hide"
                  color="cyan white--text"
                  depressed
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'customer',
                        },
                      })
                    )
                  "
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn> -->
                <!--  <v-menu content-class="custom-menu-list mt-8" left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu> -->
                <!-- <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  :close-on-content-click="false"
                  content-class="white-background"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button btn-tab-hide"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      depressed
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                
                        <v-checkbox
                          dense
                          v-model="defaultColDefs"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="cols.fixed || dataLoading"
                          color="cyan"
                          hide-details
                          true-value="true"
                          false-value="false"
                          class="mt-0 mb-0"
                        ></v-checkbox>
                        <v-icon class="draggable-drag-icon" right color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu> -->
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                  content-class="white-background"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button btn-tab-hide"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      depressed
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    v-on:change="updateVisible()"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          hide-details
                          class="mt-0 mb-0"
                          v-on:change="updateVisible()"
                        ></v-checkbox>
                        <v-icon class="draggable-drag-icon" right color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <!-- <PageTips v-if="false" module="supplier"></PageTips> -->
              </v-col>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Contact"
          :basicSearchFields="['barcode', 'company_name', 'display_name']"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      :width="cols.field == 'activated' ? cols.width : ''"
                      class="simple-table-th"
                      :class="{
                        'pointer-events-none': lodash.isEmpty(rowData),
                      }"
                    >
                      <template v-if="cols.checkbox == 1 ? true : false">
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="!lodash.isEmpty(rowData)">
                  <tr
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                    :class="{
                      'custom-border-top': index == 0,
                      'orange_lighten-5': index % 2 == 0,
                      'table-in-active-row': !data.activated,
                    }"
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                        }"
                        v-on:click="
                          Number(cols.checkbox) == 0
                            ? rowClicked(data)
                            : 'javascript:void(0)'
                        "
                        :width="cols.field == 'activated' ? cols.width : ''"
                      >
                        <template v-if="cols.checkbox == 1 ? true : false">
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'activated'">
                          <div class="d-flex">
                            <div
                              style="width: 60px !important"
                              v-on:click.prevent.stop="activeInactive(data)"
                            >
                              <v-switch
                                :disabled="
                                  data.default == 1 ||
                                  data?.customer.activated == 0
                                "
                                v-model="data.activated"
                                class="mt-0"
                                inset
                                v-on:click.prevent.stop="activeInactive(data)"
                                color="green"
                              ></v-switch>
                            </div>
                            <div class="mt-1">
                              <v-chip
                                small
                                color="blue white--text"
                                label
                                class=""
                                v-if="data.default == 1"
                              >
                                <span class="font-size-16 font-weight-500">
                                  Primary
                                </span>
                              </v-chip>
                            </div>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'image'">
                          <v-avatar size="50" color="white">
                            <v-img
                              class="supplier-image"
                              :lazy-src="$defaultProfileImage"
                              :src="
                                getProfileImage(data.profile_logo)
                                  ? getProfileImage(data.profile_logo)
                                  : $defaultProfileImage
                              "
                            >
                            </v-img>
                          </v-avatar>
                        </template>
                        <template v-else-if="cols.field == 'contact_barcode'">
                          <Barcode
                            route="contact.detail"
                            :id="data.id"
                            :barcode="data.barcode"
                          ></Barcode>
                        </template>
                        <template v-else-if="cols.field == 'contact_name'">
                          <div class="supplier-listing-supplier">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <template v-if="data && data.full_name">
                                <!-- <v-avatar
                                  class="text-uppercase white--text me-1"
                                  size="30"
                                  color="blue"
                                  >{{ data.display_name[0] }}</v-avatar
                                > -->

                                {{ data.full_name }}
                              </template>
                              <em v-else class="text-muted">
                                no contact Name</em
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'contact_email'">
                          <div class="supplier-listing-supplier">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <template v-if="data && data.primary_email">
                                {{ data.primary_email }}
                              </template>
                              <em v-else class="text-muted"> no email</em>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'Contact_mobile'">
                          <div class="customer-listing-customer">
                            <!--   <b>Mobile Number: </b> -->
                            <template v-if="data && data.primary_phone">
                              {{ data.primary_phone }}
                            </template>
                            <em v-else class="text-muted"> no Mobile Number</em>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'tag'">
                          <template v-if="data && data.category">
                            <v-chip
                              :color="data?.category_color"
                              small
                              class="text-white"
                              >{{ data.category }}</v-chip
                            >
                          </template>
                          <em v-else class="text-muted"> no tag</em>
                        </template>
                        <template v-else-if="cols.field == 'company'">
                          <div class="supplier-listing-supplier">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <template
                                v-if="
                                  data &&
                                  data.customer &&
                                  data.customer.display_name
                                "
                              >
                                <span class="text-capitalize">{{
                                  data.customer.display_name
                                }}</span>
                              </template>
                              <em v-else class="text-muted"> no company</em>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              <template v-if="getDateCountAll(data.added_at)">{{
                                data.created_at
                              }}</template>
                              <template v-else>{{
                                formatDateTime(data.added_at)
                              }}</template>
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              <template v-if="getDateCountAll(data.added_at)">{{
                                data.modified_at
                              }}</template>
                              <template v-else>{{
                                formatDateTime(data.added_at)
                              }}</template>
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td colspan="8" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no contact at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog" v-if="false">
          <template v-slot:title>Filter Contact</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="getFilterLabel(field, 'Contact')"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              depressed
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              depressed
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <ImportTemplate
          :dialog="importDialog"
          sample-url="public/import-samples/SuppliersImport.xlsx"
          api-url="supplier/import"
          title="Import Contact"
          v-on:close="importDialog = false"
        ></ImportTemplate>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Contact Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH, DOWNLOAD, POST } from "@/core/services/store/request.module";
// import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
// import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import { saveAs } from "file-saver";
import ImportTemplate from "@/view/pages/partials/Import-Template.vue";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import Barcode from "@/view/pages/partials/Barcode.vue";
import { ErrorEventBus } from "@/core/lib/message.lib";

export default {
  mixins: [CommonMixin, ListingMixin],
  name: "contact-list",
  data() {
    return {
      pageTips: false,
      exportLoading: false,
      customThead: [],
      pageModule: "contact-listing",
      routeAPI: "customerperson",
      routeName: "contact",
      routeDetailName: "contact.detail",
      status: "all",
      file: "",
      importDialog: false,
      statusList: [],

      moreActions: [
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      supplierMoreAction: [
        {
          title: "Mark as Active",
          icon: "mdi-check-all",
          action: "active",
        },
        {
          title: "Mark as In-Active",
          icon: "mdi-check-all",
          action: "inactive",
        },
      ],
    };
  },
  components: {
    draggable,
    ImportTemplate,
    // PageTips,
    PageHeaderCount,
    // Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
    Barcode,
  },
  methods: {
    activeInactive(data) {
      if (data?.customer?.activated == 0) {
        return false;
      }
      if (data.default == 1) {
        ErrorEventBus.$emit(
          "update:error",
          "The Primary Contact Person can’t be In-Active."
        );
        return false;
      }

      const _this = this;
      if (data.activated == false) {
        data.activated = 0;
      } else {
        data.activated = 1;
      }
      _this.$store
        .dispatch(PATCH, {
          url: `single-person/${data.activated}/${data.id}`,
          data: {
            id: data.id,
            status: data.activated,
          },
        })
        .then(() => {
          _this.getRows();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {});
    },
    getProfileImage(profile_logo) {
      if (profile_logo && profile_logo.file && profile_logo.file.url) {
        return profile_logo.file.url;
      }
      return null;
    },

    getBillingAddress(billing) {
      let billingArray = new Array();
      if (this.lodash.isEmpty(billing) === false) {
        if (billing.unit_no) {
          billingArray.push(billing.unit_no);
        }
        if (billing.street_1) {
          billingArray.push(billing.street_1);
        }
        if (billing.street_2) {
          billingArray.push(billing.street_2);
        }
        if (billing.zip_code) {
          billingArray.push(billing.zip_code);
        }
      }
      return billingArray.join(", ");
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkSupplierExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("supplier");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    updateMoreAction(param) {
      const _this = this;
      switch (param) {
        case "active":
          _this.bulkSupplierUpdate({
            suppliers: _this.selectedRows,
            status: 1,
          });
          break;
        case "inactive":
          _this.bulkSupplierUpdate({
            suppliers: _this.selectedRows,
            status: 0,
          });
          break;
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    importRows() {
      // const _this = this;
      const file = this.$refs.file.files[0];
      if (!file) {
        alert("No file chosen");
        return;
      }
      const data = new FormData();
      data.append("excel", file);
      const _this = this;
      _this.$store
        .dispatch(POST, {
          url: "contact/import",
          data: data,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
          _this.importDialog = false;
        });
    },
    bulkSupplierUpdate(requestObject) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "contact",
          data: requestObject,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
        });
    },
    bulkSupplierExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "contact/export",
        })
        .then(({ data }) => {
          saveAs(data, "contact-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Contact",
      },
    ]);
    ServiceFormEventBus.$on("reload:staffdata", () => {
      this.getRows();
    });
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
